$ = jQuery;

$(document).ready(function ($) {
	"use strict";

	// burger
	$('.nav-icon').click(function () {
		$(this).toggleClass('open');
		$('.main-mob').toggleClass('open-menu');
		$('body').toggleClass('disable-scroll');
		$('.header').toggleClass('open');
	});

	$('.menu-item a').click(function () {
		$('.nav-icon').removeClass('open');
		$('.main-mob').removeClass('open-menu');
		$('body').removeClass('disable-scroll');
		$('.header').removeClass('open');
	});


	// add class fixed/if top or page remove class fixed
	let $menu = $(".header");

	$(window).scroll(function () {
		if ($(this).scrollTop() > 50 && $menu.hasClass("default")) {
			$menu.removeClass("default").addClass("fixed");
		} else if ($(this).scrollTop() <= 50 && $menu.hasClass("fixed")) {
			$menu.removeClass("fixed").addClass("default");
		}
	});

	// search

	$('.header--search-button').click(function () {
		$('.header--search').slideToggle(300);
	});


	$('.hero-home--slider').slick({
		dots: true,
		infinite: false,
		speed: 1500,
		arrows: false,
		slidesToShow: 1,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 661,
				settings: {
					dots: false,
					autoplay: true,
					autoplaySpeed: 2000,
					infinite: true
				}
			}
		]
	});

	$('a[href^="#"]').on('click', function (event) {
		let target = $(this.getAttribute('href'));
		if (target.length) {
			event.preventDefault();
			$('html, body').stop().animate({
				scrollTop: target.offset().top
			}, 1000);
		}
	});

	$('.tadiran--slider').slick({
		dots: true,
		arrows: true,
		infinite: false,
		fade: true,
		cssEase: 'linear',
		slidesToShow: 1,
		slidesToScroll: 1,
	});

	$('.benefits--slider').slick({
		dots: true,
		arrows: true,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 660,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.about--slider').slick({
		dots: true,
		arrows: true,
		infinite: false,
		slidesToShow: 2,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 660,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.history--slider').slick({
		dots: true,
		arrows: true,
		infinite: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 660,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.catalog--slider-wrp').slick({
		dots: true,
		arrows: true,
		infinite: false,
		fade: true,
		cssEase: 'linear',
		slidesToShow: 1,
		slidesToScroll: 1,
	});

	$('.product-parameters--block-slider').slick({
		dots: true,
		arrows: true,
		infinite: false,
		cssEase: 'linear',
		slidesToShow: 4,
		slidesToScroll: 1,

		responsive: [
			{
				breakpoint: 1681,
				settings: {
					slidesToShow: 3
				}
			},
			{
				breakpoint: 1440,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 967,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.popup-modal').magnificPopup({
		type: 'inline',
		preloader: false,
		focus: '#username',
		modal: true
	});
	$(document).on('click', '.popup-modal-dismiss', function (e) {
		e.preventDefault();
		$.magnificPopup.close();
	});



});

